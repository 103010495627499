<template>
  <div id="app">
    <div class="login">
      <!-- <div class="circle"></div> -->
      <div class="title">
        <!-- <img src="@/assets/img/logo-white.png" alt="logo">
        <div>复变云愈</div> -->
        <div class="top">
          <div class="left">
           <div class="img">
             <img src="@/assets/img/logo-white.png" alt="logo">
           </div>
           <div>复变云愈</div>
          </div>
          <div class="center"></div>
          <div class="right">
            <div class="img">
              <img src="@/assets/img/qrcode.jpg" alt="logo">
            </div>
           <div class="text">扫码下载</div>
          </div>
        </div>
        <h1>心理测评系统</h1>
        <div class="bottom">
          <div class="en">FubianYunyu<br/>psychological assessment system</div>
          <div class="copyright">copyright © 2020 复变医疗科技有限公司出品</div>
        </div>
      </div>
      <el-form class="login-box" :model="form" :rules="rules" ref="form">
        <div class="ban">
          <div class="ban-item" :class="{'active': loginWay === 1}" @click="loginWay = 1">
            <div>普通用户登录</div>
          </div>
          <div class="ban-item" :class="{'active': loginWay === 2}" @click="loginWay = 2">
            <div>管理员登录</div>
          </div>
        </div>
        <div v-show="loginWay === 1">
          <el-form-item prop="phone">
            <el-input v-model="form.phone" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input v-model="form.code" placeholder="验证码" @keyup.enter.native="login()">
              <span slot="suffix" class="suffix-code" :class="{'disabled': waitCode}" @click="getCode()">{{codeSuffix}}</span>
            </el-input>
          </el-form-item>
          <el-form-item prop="agree" class="agree">
            <el-checkbox v-model="form.agree">
              阅读并同意<a href="/agreements#/user" target="blank">用户协议</a>及<a href="/agreements#/privacy" target="blank">隐私政策</a>
            </el-checkbox>
          </el-form-item>
        </div>
        <div v-show="loginWay === 2">
          <el-form-item prop="username">
            <el-input v-model="form.username" placeholder="用户名"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="form.password" placeholder="密码" @keyup.enter.native="login()"></el-input>
          </el-form-item>
          <p class="login-tips" v-if="!user">忘记密码？请联系复变超管</p>
        </div>
        <el-form-item class="form-button">
          <el-button class="w-100" type="primary" @click="login()">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getCode, postUserLogin, postAdminLogin } from '@/api/modules/login.js'
// import md5 from 'js-md5'
export default {
  name: 'App',
  data () {
    const validateAgree = (rule, value, callback) => {
      if (value) {
        callback()
      } else {
        callback(new Error('请阅读协议并勾选'))
      }
    }
    return {
      curStatus: false,
      form: {
        phone: '',
        code: '',
        agree: false,
        username: '',
        password: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { type: 'string', pattern: /^1[0-9]{10}$/, message: '请输入正确手机号格式', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        agree: [
          { validator: validateAgree, trigger: 'change' }
        ],
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      loginWay: 1,
      waitCode: false,
      waitCnt: 60
    }
  },
  computed: {
    codeSuffix () {
      return this.waitCode ? `${this.waitCnt}S后重新发送` : '发送验证码'
    }
  },
  methods: {
    getCode () {
      this.$refs.form.validateField('phone', (err) => {
        if (!err) {
          getCode(this.form.phone).then(res => {
            console.log(res)
            this.waitCode = true
            this.waitCnt = 10
            const timer = setInterval(() => {
              this.waitCnt--
              if (this.waitCnt === -1) {
                clearInterval(timer)
                this.waitCode = false
              }
            }, 1000)
          }).catch(err => {
            console.log(err)
          })
        }
      })
    },
    login () {
      const propArr = this.loginWay === 1 ? ['phone', 'code', 'agree'] : ['username', 'password']
      let valid = true
      this.$refs.form.validateField(propArr, (err) => {
        console.log('err', err)
        if (err) {
          valid = false
        }
      })
      if (valid) {
        if (this.loginWay === 1) {
          this.userLogin()
        } else {
          this.adminLogin()
        }
      }
    },
    userLogin () {
      const data = {
        username: this.form.phone,
        smsCode: this.form.code
      }
      postUserLogin(data).then(res => {
        this.$notify({
          title: '成功',
          message: '登录成功',
          type: 'success',
          duration: 1000
        })
        // 单设备登录轮询
        sessionStorage.replaceLogin = 'true'
        setTimeout(() => {
          window.location.href = '/user#/'
          localStorage.phone = this.form.phone
        }, 1000)
      }).catch(err => {
        console.log(err)
      })
    },
    adminLogin () {
      const data = {
        username: this.form.username,
        password: this.form.password
      }
      postAdminLogin(data).then(({ data = {} }) => {
        const { type } = data
        this.$notify({
          title: '成功',
          message: '登录成功',
          type: 'success',
          duration: 1000
        })
        // console.log(type, '管理员权限')
        if (type === 'superadmin') {
          setTimeout(() => {
            window.location.href = '/admin#/'
          }, 1000)
        } else if (type === 'org_superadmin') {
          setTimeout(() => {
            window.location.href = '/workbench#/'
          }, 1000)
        } else if (type === 'org_admin') {
          window.location.href = '/workbench#/'
        } else if (type === 'xxx') {}
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.cur-round {
  border-radius: 40px;
}
.login-box {
  .el-input__inner {
    .cur-round;
    border-color: #34B7B9 !important;
  }
  form {
    position: relative;
  }
  .form-button {
    margin-bottom: 0 !important;
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: 40px;
  }
  .el-button {
    .cur-round;
    background: #41D7C3;
    border: none;
    outline: none;
    height: 45px;
    line-height: 45px;
    font-size: 18px;
    margin-top: 2rem;
    padding: 0;
    i {
      font-weight: bold;
      font-size: 2rem;
      transform: translateY(5px);
    }
  }
  .el-form-item {
    margin-bottom: 30px;
  }
  .el-form-item__error {
    padding-top: 0;
    padding-left: 1rem;
    line-height: 30px;
  }
  .agree {
    .el-form-item__content {
      line-height: 20px;
      label {
        margin-bottom: 0;
      }
    }
  }
}
.el-checkbox {
  .is-focus .el-checkbox__inner {
    border-color: #32BEC0;
  }
  .is-checked .el-checkbox__inner {
    background-color: #32BEC0;
    border-color: #32BEC0;
  }
  .el-checkbox__inner:hover {
    border-color: #32BEC0;
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #606266;
  }
  a {
    color: #32BEC0;
  }
}
</style>

<style lang="less" scoped>
#app {
  height: 100vh;
}
.login {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9;
  background: linear-gradient(180deg, #32BEC0 0%, #34D3C8 100%);
  overflow: hidden;
  .circle {
    width: 150vh;
    height: 150vh;
    position: absolute;
    left: -25vh;
    top: -25vh;
    background: linear-gradient(17deg, rgba(50, 192, 193, 0) 0%, #4BDDC6 100%);
    border-radius: 50%;
    z-index: 0;
  }
}
.title {
  position: absolute;
  top: 50%;
  left: 18.75%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 22px;
  .top{
    display: flex;
    width: 100%;
    height: 116px;
    .left{
      img {
    width: 80px;
    height: 80px;
    margin: 0 0 8px 7px;
    }
    }
    .center{
      margin: 0 30px;
      width: 1px;
      height: 80px;
      border-right: 1px solid #FFFFFF;
    }
    .right{
      width: 80px;
      text-align: center;
      display: flex;
      flex-direction: column;
      .img{
        width: 80px;
        height: 80px;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 67px;
          height: 67px;
        }
      }
      .text{
        padding-top: 12px;
        font-size: 14px;
      }
    }
  }
  h1 {
    font-size: 32px;
    margin-top: 14px;
    font-weight: bold;
  }
  .bottom {
    margin-top: 100px;
  }
  .en {
    font-family: Microsoft Himalaya;
    font-size: 24px;
    line-height: 1;
  }
  .copyright {
    font-size: 14px;
  }
}
.login-box {
  max-width: 360px;
  min-height: 440px;
  width: 90%;
  position: absolute;
  top: 50%;
  right: 19.5%;
  transform: translateY(-50%);
  background: #fff;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 40px;
  .ban {
    display: flex;
    text-align: center;
    color: #333;
    margin-bottom: 49px;
    .ban-item {
      width: 50%;
      div {
        cursor: pointer;
        display: inline-block;
        padding-bottom: 10px;
      }
    }
    .active div {
      color: #34B7B9;
      border-bottom: 1px solid #34B7B9;
    }
  }
}
.login-tips {
  font-size: 14px;
  text-align: right;
  color: #999999;
}
.suffix-code {
  cursor: pointer;
  color: #32BEC0;
  margin-right: 5px;
  &.disabled {
    color: #999;
  }
}
</style>
